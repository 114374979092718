import React from "react";
import { Form, InputGroup, FormControlProps } from "react-bootstrap";
import { useField } from "formik";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";

type TextInputProps = {
  type?: string;
  className?: string;
  errClassName?: string;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  groupProps?: Object;
  inputGroupProps?: Object;
  inputGroupTextProps?: Object;
  inputGroupText?: React.ReactNode | string;
  label?: string;
  required?: boolean;
  textarea?: boolean;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  rows?: string;
  onFocus?: () => void;
  customClass?: [string, string];
} & FormControlProps;

const TextInput: React.FC<TextInputProps> = ({
  label,
  required = false,
  name,
  type,
  textarea,
  prepend,
  append,
  groupProps,
  inputGroupProps,
  inputGroupText,
  inputGroupTextProps,
  errClassName,
  ...props
}) => {
  const [field, meta] = useField(name);
  return (
    <Form.Group className={props.customClass ? props.customClass[1] : ''} {...groupProps}>
      {label && (
        <Form.Label className={props.customClass ? props.customClass[0] : ''}>
          {label}
          {required && <RequiredFieldSymbol />}
        </Form.Label>
      )}

      {textarea ? (
        <Form.Control as="textarea" {...field} {...props} />
      ) : prepend || append ? (
        <InputGroup {...inputGroupProps}>
          {prepend && <InputGroup.Prepend>{prepend}</InputGroup.Prepend>}
          <Form.Control type={type || "text"} {...field} {...props} />
          {append && <InputGroup.Append>{append}</InputGroup.Append>}
        </InputGroup>
      ) : (
        <Form.Control type="text" {...field} {...props} />
      )}

      {meta.touched && meta.error && (
        <InputErrorMessage
          className={`error-message ${errClassName && errClassName}`}
          errorMessage={meta.error || ""}
        />
      )}
    </Form.Group>
  );
};

export { TextInput };
