import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./Dashboard.scss";
import UserLoginIcon from "src/assets/svgrImages/UserLoginIcon";
import UserRectangleIcon from "src/assets/svgrImages/UserRectangleIcon";
import LabsIcon from "src/assets/svgrImages/Labs";
import LoginInfographics from "./LoginInfographics";
import LabInfographics from "./LabInfographics";
import AuditInfographics from "./AuditInfographics";

const Dashboard = () => {
  const [activeTab, setActiveTab] = React.useState("login");
  const handleTabChange = (newTab) => setActiveTab(newTab);

  return (
    <div className="dashboard-section tabs-layout">
      <span className="mb-2 tabs-layout-header">
        <b> Dashboard </b>
      </span>
      <Tabs
        activeKey={activeTab}
        id="dashboard-tabs"
        onSelect={handleTabChange}
      >
        <Tab
          eventKey="login"
          title={
            <>
              <UserLoginIcon
                className={activeTab === "login" && "icon-enabled"}
              />
              <span> Login </span>
            </>
          }
        >
          <LoginInfographics activeTab={activeTab} />
        </Tab>
        <Tab
          eventKey="labs"
          title={
            <>
              <LabsIcon className={activeTab === "labs" && "icon-enabled"} />
              <span> Labs </span>
            </>
          }
        >
          <LabInfographics activeTab={activeTab} />
        </Tab>
        <Tab
          eventKey="users"
          title={
            <>
              <UserRectangleIcon
                className={activeTab === "users" && "icon-enabled"}
              />
              <span> Users </span>
            </>
          }
        >
          <AuditInfographics activeTab={activeTab} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Dashboard;
